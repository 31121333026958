import axios from 'axios';
import api from './api';

export default {
    async getResult(resultId, withUser = false, withOrder = true) {
        const response = await api.get(`result/${resultId}`, { 'with-user': withUser, 'with-order': withOrder });
        return response;
    },

    async getResultWithHistoricalData(resultId, withUser = false, withOrder = true) {
        const response = await api.get(`result/get/with-historical-data/${resultId}`, { 'with-user': withUser, 'with-order': withOrder });
        return response;
    },

    async getResultWithHistoricalDataForOrderId(orderId, withUser = false, withOrder = true) {
        const response = await api.get(`order/get-result/with-historical-data/${orderId}`, { 'with-user': withUser, 'with-order': withOrder });

        return response;
    },

    async updateResult(resultId, result) {
        const response = await api.post(`result/update/${resultId}`, result);
        return response;
    },

    async deleteResult(resultId) {
        const response = await api.delete(`result/delete/${resultId}`);
        return response;
    },

    async getAllResults(withUser = false, { page = 1, page_size = 20, query_string = null, finished = false } = {}) {
        const response = await api.post(
            'result/get/all',
            {
                page,
                page_size,
                query_string,
                finished
            },
            { 'with-user': withUser, 'with-order': true }
        );
        return response;
    },

    async getAllResultsForAUser(userId, withUser = false, withOrder = true) {
        const response = await api.get(`result/get/all/${userId}`, { 'with-user': withUser, 'with-order': withOrder });
        return response;
    },

    async getAllReferredResultsByPartner(
        partnerCode = null,
        { page = 1, page_size = 20, query_string = null } = {},
        withUser = true,
        withOrder = true
    ) {
        const response = await api.post(
            `result/get/all/for-partner/${partnerCode}`,
            {
                page,
                page_size,
                query_string
            },
            { 'with-user': withUser, 'with-order': withOrder }
        );

        return response;
    },

    async assignDoctorToResult(resultId, doctorId) {
        const response = await api.post(`result/assign-doctor/${resultId}`, { doctorId });
        return response;
    },

    async unassignDoctorToResult(resultId) {
        const response = await api.get(`result/unassign-doctor/${resultId}`);
        return response;
    },

    async updateCommentOnResult(resultId, comment, mustSendComment, notifyUser = false) {
        const response = await api.post(`result/update/comment/${resultId}`, { comment, mustSendComment, notifyUser });

        return response;
    },

    async exportResult(resultId, fileType = 'pdf') {
        const response = await api.get(`result/${resultId}/file?fileFormat=${fileType}`);

        const url = response.url;

        // get the file from the url
        const file = await axios.get(url, { responseType: 'blob' });

        return file;
    },
    async finishResult({ id, comment, resolution, mustSendComment } = {}) {
        const response = await api.post(`result/update/finished/${id}`, { comment, resolution, mustSendComment });

        return response;
    }
};
