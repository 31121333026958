<template>
    <div>
        <article class="w-100">
            <div class="d-flex flex-column flex-lg-row w-100">
                <div class="d-flex flex-row w-100">
                    <div class="card card-body text-left col p-3 pt-0 mx-2 mx-sm-2 my-2">
                        <span class="fa-stack fa-1x mb-2">
                            <i class="fas fa-circle fa-stack-2x" style="color: #d5b3de" />
                            <i class="fas fa-user-tag fa-stack-1x" style="color: #444444" />
                        </span>
                        <label class="mr-1 secondary-label small">{{ GENERAL.nameColumn }}</label>

                        <span v-if="patientNameInResult"><b>Lab Form Name:</b> {{ patientNameInResult }}</span>
                        <span v-else><b>In-App Name:</b> {{ patientToUse?.name }}</span>

                        <small class="mt-2"
                            ><span><b>ID:</b> {{ patientToUse?.id }}</span></small
                        >

                        <small
                            ><span><b>Email:</b> {{ patientToUse?.email }}</span></small
                        >
                        <small v-if="result?.order?.shipping"
                            ><span><b>Phone:</b> {{ result?.order?.shipping?.phone || 'N/A' }}</span></small
                        >
                        <small v-if="patientNameInResult"
                            ><span><b>In-App Name:</b> {{ patientToUse?.name }}</span></small
                        >
                    </div>
                    <div class="card card-body text-left col p-3 pt-0 mx-2 mx-sm-2 my-2">
                        <span class="fa-stack fa-1x mb-2">
                            <i class="fas fa-circle fa-stack-2x" style="color: #d5b3de" />
                            <i class="fas fa-venus-mars fa-stack-1x" style="color: #444444" />
                        </span>
                        <label class="mr-1 secondary-label small">{{ GENERAL.genderColumn }}</label>
                        <span v-if="patientToUse?.gender">{{ patientToUse?.gender }}</span>
                        <span v-else>
                            {{ GENERAL.notSet }}
                        </span>
                    </div>
                </div>
                <div class="d-flex flex-row w-100">
                    <div class="card card-body text-left col p-3 pt-0 mx-2 my-2">
                        <span class="fa-stack fa-1x mb-2">
                            <i class="fas fa-circle fa-stack-2x" style="color: #d5b3de" />
                            <i class="fas fa-birthday-cake fa-stack-1x" style="color: #444444" />
                        </span>
                        <label class="mr-1 secondary-label small">DOB</label>
                        <span v-if="result?.PID?.dob"> <b>Lab Form DOB:</b> {{ result?.PID?.dob }} ({{ result?.PID?.age }} yo) </span>
                        <span v-else> {{ patientToUse?.dob }} ({{ patientToUse?.age }} yo) </span>
                        <small v-if="result?.PID?.dob"
                            ><span><b>In-App DOB:</b> {{ patientToUse?.dob }} ({{ patientToUse?.age }} yo)</span></small
                        >
                    </div>
                    <div class="card card-body text-left p-3 pt-0 mx-2 my-2">
                        <div class="d-flex">
                            <div class="d-flex flex-column align-items-start mr-2">
                                <span class="fa-stack fa-1x mb-2">
                                    <i class="fas fa-circle fa-stack-2x" style="color: #d5b3de" />
                                    <i class="fa fa-arrow-up fa-stack-1x" style="color: #444444" />
                                </span>
                                <label class="mr-1 secondary-label small">{{ GENERAL.heightColumn }}</label>
                                <span v-if="height.heightUnitValue">
                                    <span v-if="height.heightUnitSecondaryValue">
                                        {{ height.heightUnitSecondaryValue }}
                                        {{ UNIT_SYSTEMS.imperial.heightUnitSecondary }}
                                    </span>
                                    <span> {{ height.heightUnitValue }} {{ heightUnit }} </span>
                                </span>
                                <span v-else>
                                    {{ GENERAL.notSet }}
                                </span>
                            </div>
                            <div class="d-flex flex-column align-items-start mx-2">
                                <span class="fa-stack fa-1x mb-2">
                                    <i class="fas fa-circle fa-stack-2x" style="color: #d5b3de" />
                                    <i class="fas fa-balance-scale fa-stack-1x" style="color: #444444" />
                                </span>
                                <label class="mr-1 secondary-label small">{{ GENERAL.weightColumn }}</label>
                                <span v-if="weight.weightUnitValue"> {{ weight.weightUnitValue }} {{ weightUnit }} </span>
                                <span v-else>
                                    {{ GENERAL.notSet }}
                                </span>
                            </div>
                            <div class="d-flex flex-column align-items-start ml-2">
                                <span class="fa-stack fa-1x mb-2">
                                    <i class="fas fa-circle fa-stack-2x" style="color: #d5b3de" />
                                    <i class="fas fa-balance-scale fa-stack-1x" style="color: #444444" />
                                </span>
                                <label class="mr-1 secondary-label small">BMI</label>
                                <span v-if="patientToUse.bmi"> {{ patientToUse.bmi.toFixed(2) }} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row w-100 mx-auto mx-2">
                <div class="card card-body text-left p-3 pt-0 mx-2 my-2">
                    <div v-if="result" class="additional-info mb-3">
                        <span class="fa-stack fa-1x mb-2">
                            <i class="fas fa-circle fa-stack-2x" style="color: #d5b3de" />
                            <i class="fas fa-eyedropper fa-stack-1x" style="color: #444444" />
                        </span>
                        <label class="mr-1 secondary-label small">Draw Info</label>
                        <div v-if="fillerOrderNumber">Lab Order Number: {{ fillerOrderNumber }}</div>
                        <div v-if="relevantClinicalInfo">{{ relevantClinicalInfo }}</div>
                        <div v-if="specimenReceivedDateTime">Specimen Collection Date Time: {{ specimenReceivedDateTime }}</div>
                        <div v-if="!relevantClinicalInfo && !specimenReceivedDateTime">Nothing sent by the Lab about the draw</div>
                    </div>
                    <div class="additional-info">
                        <span class="fa-stack fa-1x mb-2">
                            <i class="fas fa-circle fa-stack-2x" style="color: #d5b3de" />
                            <i class="fas fa-user fa-stack-1x" style="color: #444444" />
                        </span>
                        <label class="mr-1 secondary-label small">Other Info</label>

                        <!-- Chronic Illnesses -->
                        <div v-if="patientToUse?.profileData?.chronicIllnesses?.length"><b>Chronic Illnesses: </b>{{ chronicIllnessesLabels }}</div>

                        <!-- Respiratory Conditions -->
                        <div>
                            <b>Respiratory Conditions: </b>
                            <span v-if="patientToUse?.profileData?.respiratoryConditions?.length">
                                {{ respiratoryConditionsLabels }}
                            </span>
                            <div v-else>Not specified</div>
                        </div>

                        <!-- Pregnancy Status -->
                        <div v-if="patientToUse?.profileData?.pregnancyStatus">
                            <b>Pregnancy: </b>{{ patientToUse.profileData.pregnancyStatus ? 'Pregnant' : 'Not pregnant' }}
                            <span v-if="patientToUse.profileData.pregnancyDate">
                                (Last period date: {{ beautifyDate(patientToUse.profileData.pregnancyDate) }})
                            </span>
                        </div>

                        <!-- Menopause -->
                        <div v-if="patientToUse?.profileData?.menopause">
                            <b>Menopause: </b>{{ patientToUse.profileData.menopause }}
                            <span v-if="patientToUse?.profileData?.menopause?.toLowerCase() === 'yes'"
                                >(Symptoms: {{ menopauseSymptomsLabels }})</span
                            >
                        </div>

                        <!-- Perimenopause -->
                        <div v-if="patientToUse?.profileData?.perimenopause">
                            <b>Perimenopause: </b>{{ patientToUse.profileData.perimenopause }}
                            <span v-if="patientToUse?.profileData?.perimenopause?.toLowerCase() === 'yes'"
                                >(Symptoms: {{ perimenopauseSymptomsLabels }})</span
                            >
                        </div>

                        <!-- Ethnicity -->
                        <div v-if="patientToUse?.profileData?.ethnicity"><b>Ethnicity: </b>{{ ethnicityLabel }}</div>

                        <!-- Diet Info -->
                        <div v-if="patientToUse?.profileData?.dietInfo?.length"><b>Diet Info: </b>{{ dietInfoLabel }}</div>

                        <!-- Contraception Use -->
                        <div v-if="patientToUse?.profileData?.contraceptionUse"><b>Contraception Use: </b>{{ contraceptionUseLabel }}</div>

                        <!-- HRT Use -->
                        <div v-if="patientToUse?.profileData?.hrtUse"><b>HRT Use: </b>{{ hrtUseLabel }}</div>

                        <!-- Alcohol Information -->
                        <div v-if="patientToUse?.profileData?.alcoholInformation || isNumber(patientToUse?.profileData?.alcoholInformation)">
                            <b>Alcohol Consumption: </b>
                            <span v-if="patientToUse?.profileData?.alcoholInformation || isNumber(patientToUse?.profileData?.alcoholInformation)"
                                >{{ patientToUse.profileData.alcoholInformation }} units per week</span
                            >
                            <span v-else>Not specified</span>
                        </div>

                        <!-- Smoking Information -->
                        <div v-if="patientToUse?.profileData?.smokingInformation"><b>Smoking Habits: </b>{{ smokingInformationLabel }}</div>

                        <!-- Family History -->
                        <div v-if="patientToUse?.profileData?.familyHistory?.length"><b>Family History: </b>{{ familyHistoryLabels }}</div>

                        <!-- Fallback if no other information is present -->
                        <div v-if="isProfileDataEmpty">Nothing else mentioned by the user</div>
                    </div>

                    <hr v-if="patientToUse?.profileData?.additionalInformation?.length" />
                    <small v-if="patientToUse?.profileData?.additionalInformation?.length">
                        <label class="mb-1 pb-0 font-weight-bold">Additional Information from the Patient</label>
                        <div
                            v-for="(info, index) in patientToUse.profileData.additionalInformation"
                            :key="index"
                            class="d-flex flex-row justify-content-between my-2">
                            <div class="w-50">
                                <span class="text-muted">(On {{ beautifyDate(info.date) }}):</span> <br />

                                <!-- Display optional startDate and endDate if they exist -->
                                <div v-if="info.data.startDate || info.data.endDate" class="mt-1">
                                    <span v-if="info.data.startDate" class="text-muted"> Start Date: {{ beautifyDate(info.data.startDate) }} </span>
                                    <span v-if="info.data.endDate" class="text-muted ml-3"> End Date: {{ beautifyDate(info.data.endDate) }} </span>
                                </div>

                                <!-- Preserve new lines for the note -->
                                <div class="d-flex flex-row w-100 align-items-center mt-2">
                                    <div class="border p-2 w-100">
                                        <span style="white-space: pre-wrap; word-wrap: break-word">
                                            {{ info.data.note }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </small>

                    <hr v-if="result?.order?.additionalInformation?.length" />
                    <small v-if="result?.order?.additionalInformation?.length">
                        <label class="mb-1 pb-0 font-weight-bold">Other notes/Information from the order</label>
                        <div
                            v-for="(info, index) in result?.order?.additionalInformation"
                            :key="index"
                            class="d-flex flex-row justify-content-between my-2">
                            <div class="w-50">
                                <span class="text-muted">(On {{ beautifyDate(info.date) }}):</span> <br />
                                <!-- preserve new lines etc-->
                                <div class="d-flex flex-row w-100 align-items-center">
                                    <div class="border p-2 w-100">
                                        <span style="white-space: pre-wrap; word-wrap: break-word">
                                            {{ info.data }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </small>
                </div>
            </div>

            <!-- <div
                v-if="showQuestionnaireAnswers && healthcareQuestionnaireFilteredVariables"
                class="d-flex flex-column w-100 w mx-auto flex-sm-row mt-3">
                <div class="card card-body p-3">
                    <p class="lead fw-normal mb-1 d-flex justify-content-md-between flex-column flex-md-row align-items-center align-items-md-start">
                        Health and Nutrition Questionnaire ({{ isTheQuestionnaireCurrent ? 'Current one' : 'When result came' }})
                    </p>
                    <p v-for="(question, index) in healthcareQuestionnaireFilteredVariables" :key="index" class="mb-2">
                        <small> {{ question.title }}: </small>
                        <small v-if="getUserResponse(question.id, userHealthcareQuestionnaire[question.id])" class="font-weight-bold">
                            {{ getUserResponse(question.id, userHealthcareQuestionnaire[question.id]) }}
                        </small>
                    </p>
                </div>
            </div> -->
        </article>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { cmToInch, feetAndInchToInchOnly, inchToCm, inchToFeet, inchToRemainingInch, kgToLbs, lbsToKg } from '@/utils/utils';
import {
    healthcareQuestionnaireVariables,
    GENERAL,
    UNIT_SYSTEMS,
    ETHNICITY_LABELS,
    DIET_INFO_LABELS,
    CONTRACEPTION_USE_LABELS,
    HRT_USE_LABELS,
    SMOKING_INFORMATION_LABELS,
    FAMILY_HISTORY_LABELS,
    RESPIRATORY_CONDITIONS_TYPE,
    CHRONIC_ILLNESS_TYPE,
    PERI_AND_MENOPAUSE_SYMPTOMS
} from '../utils/constants';
import moment from 'moment';
import _ from 'lodash';

export default {
    props: {
        order: {
            type: Object
        },
        result: {
            type: Object
        },
        userId: {
            type: String
        },
        showQuestionnaireAnswers: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            GENERAL,
            UNIT_SYSTEMS,
            userProfile: {},
            healthcareQuestionnaireVariables,
            height: {
                heightUnitValue: null,
                heightUnitSecondaryValue: null
            },
            weight: {
                weightUnitValue: null
            },
            unitSystem: '',
            ETHNICITY_LABELS,
            DIET_INFO_LABELS,
            CONTRACEPTION_USE_LABELS,
            HRT_USE_LABELS,
            SMOKING_INFORMATION_LABELS,
            FAMILY_HISTORY_LABELS,
            RESPIRATORY_CONDITIONS_TYPE,
            CHRONIC_ILLNESS_TYPE
        };
    },
    computed: {
        ...mapGetters({
            allPatients: 'user/allPatients',
            allUsers: 'user/allUsers',
            loggedUser: 'user/currentUser'
        }),
        userHealthcareQuestionnaire() {
            return this.result?.PID
                ? this.result.PID?.healthcareQuestionnaire ||
                      (this.patientToUse.profileComplete || this.patientToUse.profilePartialCompleted
                          ? this.patientToUse.healthcareQuestionnaire
                          : null)
                : this.patientToUse.profileComplete || this.patientToUse.profilePartialCompleted
                ? this.patientToUse.healthcareQuestionnaire
                : null;
        },
        isTheQuestionnaireCurrent() {
            return !this.result?.PID?.healthcareQuestionnaire;
        },
        patientNameInResult() {
            return _.isString(this.result?.result?.PID?.patientName)
                ? this.result?.result?.PID?.patientName
                : this.result?.result?.PID?.patientName
                      ?.filter(Boolean)
                      ?.filter((x) => x !== 'MASTER')
                      .join(' ');
        },
        fillerOrderNumber() {
            return this.result?.result?.OBR?.fillerOrderNumber || this.result?.OBR?.fillerOrderNumber;
        },
        relevantClinicalInfo() {
            return this.result?.result?.OBR?.relevantClinicalInfo || this.result?.OBR?.relevantClinicalInfo;
        },
        specimenReceivedDateTime() {
            return this.result?.result?.OBR?.specimenReceivedDateTime || this.result?.OBR?.specimenReceivedDateTime;
        },
        healthcareQuestionnaireFilteredVariables() {
            // eslint-disable-next-line no-confusing-arrow
            const toTake = _.map(this.healthcareQuestionnaireVariables, (v) =>
                this.userHealthcareQuestionnaire && this.userHealthcareQuestionnaire[v.id]?.value ? v.id : null
            ).filter((x) => x);

            if (!toTake || !toTake.length) return null;

            return _.pick(this.healthcareQuestionnaireVariables, toTake);
        },
        patientToUse() {
            if (this.userId) {
                return this.allPatients.find((p) => p.id === this.userId) || this.allUsers.find((u) => u.id === this.userId);
            }

            return this.result.user;
        },
        weightUnit() {
            if (this.loggedUser.unitSystem === UNIT_SYSTEMS.imperial.id) {
                return UNIT_SYSTEMS.imperial.weightUnit;
            }

            return UNIT_SYSTEMS.metric.weightUnit;
        },
        heightUnit() {
            if (this.loggedUser.unitSystem === UNIT_SYSTEMS.imperial.id) {
                return UNIT_SYSTEMS.imperial.heightUnitMain;
            }

            return UNIT_SYSTEMS.metric.heightUnitMain;
        },
        isProfileDataEmpty() {
            const profile = this.patientToUse?.profileData;

            return !(
                profile?.chronicIllnesses?.length ||
                profile?.respiratoryConditions?.length ||
                profile?.pregnancyStatus ||
                profile?.menopause ||
                profile?.perimenopause ||
                profile?.ethnicity ||
                profile?.dietInfo?.length ||
                profile?.contraceptionUse ||
                profile?.hrtUse ||
                profile?.alcoholInformation ||
                profile?.smokingInformation ||
                profile?.familyHistory?.length
            );
        },
        ethnicityLabel() {
            return ETHNICITY_LABELS[this.patientToUse?.profileData?.ethnicity] || 'Not specified';
        },
        // Map diet info key to label
        dietInfoLabel() {
            return this.patientToUse?.profileData?.dietInfo.map((item) => DIET_INFO_LABELS[item] || item).join(', ') || 'Not specified';
        },
        // Map contraception use key to label
        contraceptionUseLabel() {
            return (
                CONTRACEPTION_USE_LABELS[this.patientToUse?.profileData?.contraceptionUse] ||
                this.patientToUse?.profileData?.contraceptionUse ||
                'Not specified'
            );
        },
        // Map HRT use key to label
        hrtUseLabel() {
            return HRT_USE_LABELS[this.patientToUse?.profileData?.hrtUse] || this.patientToUse?.profileData?.hrtUse || 'Not specified';
        },
        // Map smoking information key to label
        smokingInformationLabel() {
            return (
                SMOKING_INFORMATION_LABELS[this.patientToUse?.profileData?.smokingInformation] ||
                this.patientToUse?.profileData?.smokingInformation ||
                'Not specified'
            );
        },
        // Map family history array to labels
        familyHistoryLabels() {
            return this.patientToUse?.profileData?.familyHistory?.map((item) => FAMILY_HISTORY_LABELS[item] || item).join(', ') || 'Not specified';
        },
        // Map respiratory conditions array to labels
        respiratoryConditionsLabels() {
            return (
                this.patientToUse?.profileData?.respiratoryConditions?.map((item) => RESPIRATORY_CONDITIONS_TYPE[item] || item).join(', ') ||
                'Not specified'
            );
        },
        // Map chronic illnesses array to labels
        chronicIllnessesLabels() {
            return this.patientToUse?.profileData?.chronicIllnesses?.map((item) => CHRONIC_ILLNESS_TYPE[item] || item).join(', ') || 'Not specified';
        },
        perimenopauseSymptomsLabels() {
            return (
                this.patientToUse?.profileData?.perimenopauseSymptoms?.map((symptom) => PERI_AND_MENOPAUSE_SYMPTOMS[symptom] || symptom).join(', ') ||
                'Not set'
            );
        },
        // Computed for displaying menopause symptoms as a formatted string
        menopauseSymptomsLabels() {
            return (
                this.patientToUse?.profileData?.menopauseSymptoms?.map((symptom) => PERI_AND_MENOPAUSE_SYMPTOMS[symptom] || symptom).join(', ') ||
                'Not set'
            );
        }
    },
    async mounted() {
        this.userProfile = _.cloneDeep(this.patientToUse.toProfileJSON());

        if (this.loggedUser.unitSystem) {
            this.unitSystem = this.loggedUser.unitSystem;
        } else {
            this.unitSystem = UNIT_SYSTEMS.metric.id;
        }

        const heightCm = this.userProfile.height;
        const weightKg = this.userProfile.weight;

        if (this.unitSystem === UNIT_SYSTEMS.imperial.id) {
            const heightInch = cmToInch(heightCm);
            const remainingInch = inchToRemainingInch(Number(heightInch));
            const feet = inchToFeet(Number(heightInch));
            this.height.heightUnitValue = remainingInch;
            this.height.heightUnitSecondaryValue = feet;
            this.weight.weightUnitValue = kgToLbs(weightKg);
        } else {
            this.height.heightUnitValue = heightCm;
            this.weight.weightUnitValue = weightKg;
        }
    },
    methods: {
        getUserResponse(qId, response) {
            if (!qId || !response) return null;

            const q = this.healthcareQuestionnaireFilteredVariables[qId];

            if (q.options) {
                // eslint-disable-next-line no-confusing-arrow
                const r = _.find(q.options, (o) => (response?.value?.length ? response?.value.includes(o.value) : o.value === response.value));

                return response.other ? `${r?.label} - ${response.other}` : r?.label;
            }

            return response?.value || response?.other || null;
        },
        updateUnitSystem(system) {
            this.unitSystem = system;
            if (system === UNIT_SYSTEMS.imperial.id) {
                const inch = cmToInch(this.height.heightUnitValue);
                const remainingInch = inchToRemainingInch(Number(inch));
                this.height.heightUnitValue = remainingInch;
                const feet = inchToFeet(Number(inch));
                this.height.heightUnitSecondaryValue = feet;
                this.weight.weightUnitValue = kgToLbs(Number(this.weight.weightUnitValue));
            } else {
                const inchOnly = feetAndInchToInchOnly(Number(this.height.heightUnitSecondaryValue), Number(this.height.heightUnitValue));
                this.height.heightUnitValue = inchToCm(Number(inchOnly));
                this.height.heightUnitSecondaryValue = '';
                this.weight.weightUnitValue = lbsToKg(this.weight.weightUnitValue);
            }
        },
        beautifyDate(date) {
            return moment(date).format('DD/MM/YYYY HH:mm');
        },
        isNumber(value) {
            return _.isNumber(value);
        }
    }
};
</script>
